<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>财务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>晴雨统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                    <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                        日
                        </a-radio-button>
                        <a-radio-button value="week">
                        周
                        </a-radio-button>
                        <a-radio-button value="month">
                        月
                        </a-radio-button>
                        <a-radio-button value="year">
                        年
                        </a-radio-button>
                    </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>时间筛选</span>
                        </template>
                        <a-range-picker
                        :ranges="rangesData"
                        :value='rangesValue'
                        :disabled-date="disabledDate" :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                        @change="(date, dateString)=>onChange(date, dateString,1)" />
                    </a-tooltip>
                    </a-form-item>

                    <a-form-item>
                        <a-tree-select
                            v-model="paramsData.department_ids"
                            style="width:240px"
                            :tree-data="DepOptions"
                            tree-checkable
                            allowClear
                            :replaceFields="{
                                title:'department_name',
                                value: 'department_id',
                            }"
                            :maxTagCount='1'
                            :maxTagTextLength='5'
                            :dropdownStyle="{maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择部门"
                        />
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption" style="width:240px">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                    <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group" style="padding-top:15px">
                <a-row class="mb16" :gutter="16">

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="contractKey" :loading="loadingC" :item="contract_data" title="签约金额晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="contractTotalKey" :loading="loadingCT" :item="contractTotal_data" title="签约数量晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="taskKey" :loading="loadingT" :item="task_data" title="资源分配（按次）晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="followKey" :loading="loadingF" :item="follow_data" title="资源跟进（按次）晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="invitedKey" :loading="loadingI" :item="invited_data" title="邀约到访晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="revenueKey" :loading="loadingR" :item="revenue_data" title="课消晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="revenueTotalKey" :loading="loadingRT" :item="revenueTotal_data" title="课时晴雨表" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="bookKey" :loading="loadingB" :item="book_data" title="学员到课数晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="courseKey" :loading="loadingCH" :item="course_data" title="排课晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :key="refundKey" :loading="loadingRH" :item="refund_data" title="退费晴雨图" :filter_type="paramsData.filter_type" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    
                    
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import heatmap from './heatmap'
    import ranges from "@/common/mixins/ranges"
    import { TreeSelect } from 'ant-design-vue'
    const SHOW_ALL = TreeSelect.SHOW_ALL
    export default {
        name:"forecast",
        data() {
            return {
                SHOW_ALL,
                loadingT:false,
                loadingR:false,
                loadingF:false,
                loadingI:false,
                loadingRT:false,
                loadingB:false,
                loadingC:false,
                loadingCT:false,
                loadingCH:false,
                loadingRH:false,
                studios:[],
                taskKey:1,
                courseKey:1,
                followKey:1,
                revenueKey:1,
                invitedKey:1,
                revenueTotalKey:1,
                contractKey:1,
                contractTotalKey:1,
                bookKey:1,
                refundKey:1,
                DepOptions:[],
                course_data:[],
                follow_data:[],
                studios_data:[],
                parentChannels:[],
                task_data:{},
                revenue_data:{},
                invited_data:{},
                revenueTotal_data:{},
                contract_data:{},
                contractTotal_data:{},
                book_data:{},
                refund_data:{},
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    studio_id:undefined,
                    filter_type:'day'
                },
            }
        },
        components: {
            heatmap
        },
        mixins:[ranges],
        created () {
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getStudio()
            this.getDepartment()
            this.getSourceChannel()
            this.toScreen()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj) {
                this.getTaskHeatmap(obj)
                this.getCourseHeatmap(obj)
                this.getfollowHeatmap(obj)
                this.getRevenueHeatmap(obj)
                this.getRevenueTotalHeatmap(obj)
                this.getContractHeatmap(obj)
                this.getBookHeatmap(obj)
                this.getRefundHeatmap(obj)
                this.getInvitedHeatmap(obj)
                this.getContractTotalHeatmap(obj)
            },
            async getTaskHeatmap(obj){
                this.loadingR = true
                let res = await this.$store.dispatch('analysisCampusTaskHeatmapAction', obj)
                this.task_data = res.data
                this.taskKey++
                this.loadingT = false
            },
            async getCourseHeatmap(obj){
                this.loadingCH = true
                let res = await this.$store.dispatch('analysisCampusCourseHeatmapAction', obj)
                this.course_data = res.data
                this.courseKey++
                this.loadingCH = false
            },
            async getfollowHeatmap(obj){
                this.loadingF = true
                let res = await this.$store.dispatch('analysisCampusFollowHeatmapAction', obj)
                this.follow_data = res.data
                this.followKey++
                this.loadingF = false
            },
            async getRevenueHeatmap(obj){
                this.loadingR = true
                let res = await this.$store.dispatch('analysisCampusRevenueHeatmapAction', obj)
                this.revenue_data = res.data
                this.revenueKey++
                this.loadingR = false
            },
            async getRevenueTotalHeatmap(obj){
                this.loadingRT = true
                let res = await this.$store.dispatch('analysisCampusRevenueTotalHeatmapAction', obj)
                this.revenueTotal_data = res.data
                this.revenueTotalKey++
                this.loadingRT = false
            },
            async getContractHeatmap(obj){
                this.loadingC = true
                let res = await this.$store.dispatch('analysisCampusContractHeatmapAction', obj)
                this.contract_data = res.data
                this.contractKey++
                this.loadingC = false
            },
            async getContractTotalHeatmap(obj){
                this.loadingCT = true
                let res = await this.$store.dispatch('analysisCampusContractTotalHeatmapAction', obj)
                this.contractTotal_data = res.data
                this.contractTotalKey++
                this.loadingCT = false
            },
            async getBookHeatmap(obj){
                this.loadingB = true
                let res = await this.$store.dispatch('analysisCampusBookHeatmapAction', obj)
                this.book_data = res.data
                this.bookKey++
                this.loadingB = false
            },
            async getRefundHeatmap(obj){
                this.loadingRH = true
                let res = await this.$store.dispatch('analysisCampusRefundHeatmapAction', obj)
                this.refund_data = res.data
                this.refundKey++
                this.loadingRH = false
            },async getInvitedHeatmap(obj){
                this.loadingI = true
                let res = await this.$store.dispatch('analysisCampusInvitedHeatmapAction', obj)
                this.invited_data = res.data
                this.invitedKey++
                this.loadingI = false
            },
            async getSourceChannel() {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
                this.parentChannels = res.data
            },
            async getDepartment(){
                let res = await this.$store.dispatch('searchGetDepartmentAction', {})
                this.DepOptions = res.data
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
                this.studios_data = res.data.map((item)=>item.filter_name)
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj)
            },
            closeSearch() {
                this.visible = false
            },
            reset(){
                this.paramsData.channel_id = undefined
                this.paramsData.studio_id = undefined
                this.paramsData.created_by = undefined
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>