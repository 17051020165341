<template>
  <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-heatmap
              :data="item" 
              judge-width
              :height="wHeight"
              :title="{
                  text:`${title}（${item.title?item.title:''}）`,
                  subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                  textStyle: {
                      fontSize: 15,
                  },
                  subtextStyle: {
                      fontSize: 12.5
                  }
              }"
              :extend="{
                series:{
                  itemStyle:{
                    borderColor:'#fff',
                    borderWidth:1
                  },
                  label:{
                    show:true
                  }
                }
              }"
              :settings="chartSettings" 
              :visual-map="chartVisualMap" 
              :grid="chartGrid"
              >
            </ve-heatmap>
            <LEmpty v-if=" item.rows && item.rows.length == 0"/>
        </div>
    </a-card>
</template>

<script>
  import moment from 'moment'
  export default {
    name:'heatmap',
    inject: ['parent'],
    data () {
      this.chartSettings = {
        yAxisList: this.item.yAxisList
      }
      this.chartGrid = {
        right: 10,
        bottom: '10%',
      }
      this.chartVisualMap = {
        show: true,
        min: 0,
        max: 1000,
        type: 'piecewise',
        orient:'horizontal',
        left:"center",
        pieces: [
                {
                    lte:  0,
                    color:'#AAAAAA',
                    symbol:'circle'
                }, 
                {
                    gt: 0,   //大于3 小于等于6
                    lte:  this.item.average_value,
                    color:'#F5A962',
                    // color:'#FFB830',
                    symbol:'circle'
                }, 
                // {
                //     lte:  this.item.average_value,
                //     color:'#AAAAAA',
                //     symbol:'circle'
                // }, 
                {
                    gt: this.item.average_value,   //大于400
                    color:'#00cca2',
                    symbol:'circle'
                }
        ],
      }
      return {
        type:0,
        setHeight:400
      }
    },
    props:['item','loading','title','start_date','end_date','filter_type'],
    computed: {
      wHeight() {
          let height = this.setHeight
          if( this.item.yAxisList && this.item.yAxisList.length > 10){
              height = this.setHeight + ( this.item.yAxisList.length - 10 ) * 20
          }
          return `${height}px`
      }
    },
  }
</script>
